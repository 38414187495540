import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";

function Auth({ children }) {
  const {
    user,
    userToken,
    loading,
    checkAuthLoading,
    isAuthenticated,
  } = useSelector((state) => state.user);

  if (loading) return <BeatLoader />;

  if (!user?.id || !isAuthenticated) {
    console.log(user)
    console.log("isAuthenticated",isAuthenticated)
    return <Navigate to={"/login"} />;
  }

  return <>{children}</>;
}

export default Auth;
