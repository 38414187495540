import React from 'react'
import DetailView from './DetailView'
import { useGetJobcardsQuery } from '../../../store/store';
import { useParams } from 'react-router-dom';

const MainView = () => {
    const {id}=useParams()
    const {
      data: jobcard,
      isLoading: jobcardloading,
      isFetching: fetchjobcard,
    } = useGetJobcardsQuery({id:id});
    if(!fetchjobcard){

      console.log(jobcard)
    }
  return (
    <div>
      <DetailView data={jobcard} isLoading={jobcardloading}/>
    </div>
  )
}

export default MainView
