import React from 'react'
import "./error.css"
const Error = () => {
  return (
    <div className='error-img-con df-jc-ac'>
      <img src="./img/construction2.jpg" alt="" />
    </div>
  )
}

export default Error
