import { url } from "../../store/mutation/url";

export const STAGES = {
  Designing: "Designing",
  Sublimation: "Sublimation",
  Cutting: "Cutting",
  Stitching: "Stitching",
  Packaging: "Packaging",
  Account: "Account",
  Completed: "Completed",
};

export const STAGES_LIST = Object.keys(STAGES).map((key) => STAGES[key]);

export const flatMap = (record, newStage) => {
  const { image, images, stage, ...data } = record;
  // delete transformedA.images;

  return { stage: stage, id: data?.id, forward_to: newStage };
};

export const MakeForm = (transformedA) => {
  const formData = new FormData();

  // Append each key-value pair to the FormData object

  Object.keys(transformedA).map((form_key) =>
    formData.append(form_key, transformedA[form_key] || "")
  );
  return formData;
};

export const stageArray = [
  "Designing",
  "Sublimation",
  "Cutting",
  "Stitching",
  "Packaging",
  "Account",
  "Completed",
];
export const GarmentTypeArray = [
  "Top Type",
  "Bottom Type",
  "Caps",
  "Socks",
  "Bag",
  "Flag",
  "Scarf",
];
export const stageArray2 = [
  "creation",
  "cutting",
  "secendory_process",
  "embroidary",
  "production",
  "account",
  "completed",
];

export function removeNaNFromArray(arr) {
  // Filter out NaN values from the array
  const filteredArray = arr.filter((value) => !Number.isNaN(value));
  return filteredArray;
}
export function arrayToQueryParam(paramName, values) {
  if (values.length === 0) {
    return ""; // Return an empty string if the array is empty
  }

  const queryParam = values.map((value) => `${paramName}=${value}`).join("&");
  return `&${queryParam}`;
}

export function queryParamToArray(queryParam) {
  if (!queryParam) {
    return []; // Return an empty array if there's no query param
  }

  // Split the query parameter string using '&' as the separator
  const paramsArray = queryParam.split("&");

  // Extract the values from the parameters
  const values = paramsArray.map((param) => {
    const value = param.split("=")[1]; // Extract the value part after '='
    return parseInt(value); // Convert the value to an integer or another appropriate type
  });

  return removeNaNFromArray(values);
}
export function queryParamToArray2(queryParam) {
  if (!queryParam) {
    return []; // Return an empty array if there's no query param
  }

  // Split the query parameter string using '&' as the separator
  const paramsArray = queryParam.split("&");

  // Extract the values from the parameters
  const values = paramsArray.map((param) => {
    const value = param.split("=")[1]; // Extract the value part after '='
    return value; // Convert the value to an integer or another appropriate type
  });
  return values.filter((item) => item !== undefined);
}
