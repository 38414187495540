import { Card, Col } from "antd";
import React from "react";

const SmallCard = ({ data }) => {
  return (
    // <div
    //   style={{
    //     padding: "20px",
    //     border: "1px solid lightgrey",
    //     boxShadow: "0px 0px 4.2px rgba(0, 0, 0, 0.25) inset",
    //   }}
    // >
    //   <h2>{data.stage}</h2>
    //   <p>{data.num_clothing_cards}</p>
    // </div>

    <div style={{width:"100%",margin:"0px 20px"}}>
      <Card
        className="hover-the-card"
        title={data.stage}
        bordered={true}
        style={{
          boxShadow:
            "0 1px 2px 0 rgb(0 0 0 / 40%), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
        }}
      >
        {data.num_clothing_cards}
      </Card>
    </div>
  );
};

export default SmallCard;
