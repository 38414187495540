import "./App.css";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./pages/home/Home";
import Error from "./pages/error/Error";
import Slidebar from "./components/header/Slidebar";
import Login from "./pages/login/Login";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "./store/mutation/UserSlice";
import Auth from "./store/auth/Auth";
import { message } from "antd";
import Footer from "./pages/footer/Footer";
import JobCard from "./pages/jobcard/JobCard";
import MainJobCard from "./pages/jobcard/form/MainJobCard";
import MainView from "./pages/jobcard/view/MainView";
import Report from "./pages/report/Report";
import MainReportView from "./pages/report/view/MainReportView";
import JobCardCompleted from "./pages/jobcard/JobCardCompleted";

function App() {
  const dispatch=useDispatch()
  useEffect(() => {
    // console.log("lion")
    dispatch(getUser())
  }, []);
  // console.log("tiger")

  return (
    <>
    {/* hello */}
      <BrowserRouter>
          <Slidebar/>
        <Routes>
          <Route path="/" element={
          <Auth>

            <Home />
          </Auth>
          } />
          <Route path="/jobcard" element={
            <Auth>

            <JobCard />
            </Auth>
            } />
          <Route path="/jobcard-completed" element={
            <Auth>

            <JobCardCompleted />
            </Auth>
            } />
          <Route path="/create-jobcard" element={
            <Auth>

            <MainJobCard />
            </Auth>
            } />
          <Route path="/reports" element={
            <Auth>

            <Report />
            </Auth>
            } />
          <Route path="/reports-detail/:id" element={
            <Auth>

            <MainReportView />
            </Auth>
            } />
          <Route path="/create-jobcard/:id" element={
            <Auth>

            <MainJobCard />
            </Auth>
            } />
          <Route path="/detail-jobcard/:id" element={
            <Auth>

            <MainView />
            </Auth>
            } />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;

//S - XXXXXL
//18 - 52 size 
//more width in crm view 
//plus scroll and separate with categoryx  