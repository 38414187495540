import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Slider,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  useCreateJobCardMutation,
  useFetchArrayQuery,
  useFetchClientsQuery,
  useUpdateJobCardMutation,
} from "../../../store/store";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import FileForm from "../../../components/multipleFIles/FileForm";
import SubCatDropDown from "../../../components/dropdowns/SubCatDropDown";
import { useNavigate } from "react-router-dom";
import { url } from "../../../store/mutation/url";
import { ConvertDate } from "../../../atoms/static/Functions";
import { GarmentTypeArray } from "../../../atoms/static/Static";
import AnimatedFileInput from "../../../atoms/AnimatedFileInput";
import TextArea from "antd/es/input/TextArea";
const CreateJobCardFun = (values, createJobcard, Submitimg) => {
  const formData = new FormData();

  // Output the formatted date
  values.date = ConvertDate(values.date);
  values.deliver_date = ConvertDate(values.deliver_date);
  if (values?.image?.target) {
    values.image = values?.image?.target?.files[0];
  }
  if (values?.images?.length > 0) {
    const updatedA = {
      ...values,
      images: values?.images?.map((item) => ({
        sizee: item.sizee,
        count: item.count,
      })),
    };

    const transformedA = { ...updatedA };
    updatedA.images.forEach((item, index) => {
      transformedA[`sizee${index + 1}`] = item.sizee;
      transformedA[`count${index + 1}`] = item.count;
    });
    delete transformedA.images;

    // Append each key-value pair to the FormData object
    // Object.keys(transformedA).forEach((key) => {
    //   formData.append(key, transformedA[key] || "");
    // });
    // Log FormData entries
    // createJobcard(formData);
    Submitimg(transformedA)

  } else {
    delete values.images;

    // Object.keys(values).forEach((key) => {
    //   formData.append(key, values[key] || "");
    // });
    Submitimg(values)
    // createJobcard(formData);
  }
};
const UpdateJobCardFun = (values, updateJobcard, Submitimg) => {
  values.date = ConvertDate(values.date);
  values.deliver_date = ConvertDate(values.deliver_date);
  // console.log(values?.image?.target?.files[0])
  if (values?.image?.target) {
    values.image = values?.image?.target?.files[0];
    var value = values;
  } else {
    var { image, ...value } = values;
  }
  const updatedA = {
    ...value,
    images: values.images.map((item) => ({
      sizee: item.sizee,
      count: item.count,
    })),
  };
  const transformedA = { ...updatedA };
  updatedA.images.forEach((item, index) => {
    transformedA[`sizee${index + 1}`] = item.sizee;
    transformedA[`count${index + 1}`] = item.count;
  });
  delete transformedA.images;


  // Log FormData entries
  // console.log(transformedA)
  Submitimg(transformedA)
  // updateJobcard({ createJobcardData: formData, id: values.id });
};
const CreateJobCard = ({ initialValues }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    data: clients,
    isLoading: clientloading,
    isFetching: fetchclient,
  } = useFetchClientsQuery();
  const [garmentType, setgarmentType] = useState("Top Type");

  const {
    data: array,
    isLoading: arrayloading,
    isFetching: fetcharray,
  } = useFetchArrayQuery();
  const [createJobcard, createResponseInfo] = useCreateJobCardMutation();
  const [updateJobcard, updateResponseInfo] = useUpdateJobCardMutation();
  useEffect(() => {
    if (initialValues) {
      // console.log("working", initialValues?.garmenttype);
      setgarmentType(initialValues?.garmenttype);
    }
  }, [initialValues]);
  useEffect(() => {
    if (createResponseInfo?.isSuccess) {
      message.success("Job Card Created");
      setLoa(false)

      navigate("/jobcard");
    }
  }, [createResponseInfo]);
  useEffect(() => {
    if (updateResponseInfo?.isSuccess) {
      message.success("Job Card Updated");
      setLoa(false)
      navigate("/jobcard");
    }
  }, [updateResponseInfo]);
  useEffect(() => {
    if (form.getFieldValue()) {
      // console.log(form.getFieldValue());
    }
  }, [form.getFieldValue()]);
  const handleSelect = (data) => {
    console.log(data)
    setgarmentType(data);
  };
  const [image, setImage] = useState();
  const [fileName, setFileName] = useState('');
  const handleFileSelect = (file) => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setFilePreviews(fileURL);

      // Extract the file name and extension
      const name = file.name;

      // Check if the file is a PDF
      if (name.toLowerCase().endsWith('.pdf')) {
        setFileName(name);
      } else {
        setFileName(''); // Clear the name if not a PDF
      }
    }
    setImage(file);
  };
  const [filePreviews, setFilePreviews] = useState();
  const [loa, setLoa] = useState(false);

  const Submitimg = async (data1) => {
    if (image) {
      const formData = new FormData();
      formData.append("image", image);

      try {
        const response = await fetch(`${url}/api/upload/`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        console.log("Image uploaded successfully!", data.fileUrl);
        data1.image = data.fileUrl;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    if (initialValues?.id) {
      console.log("update", data1);
      // updateStaff(data1)
      updateJobcard({ createJobcardData: data1, id: initialValues?.id })
    } else {
      // createStaff(data1);
      console.log("create", data1);
      createJobcard(data1);

    }
    // }
  };
  useEffect(() => {
    if (initialValues?.id) {
      setFilePreviews(initialValues?.image);
    }
  }, [initialValues]);
  return (
    <div className="create-job-card-container">
      <h1 className="create-job-card-title">
        {initialValues?.id ? "Update Job Card" : "Create Job Card"}
      </h1>
      <Form
        form={form}
        // onChange={(data) => console.log(data)}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        onFinish={(values) => {
          setLoa(true)
          initialValues?.id
            ? UpdateJobCardFun(values, updateJobcard, Submitimg)
            : CreateJobCardFun(values, createJobcard, Submitimg);
        }}
        initialValues={initialValues}
      >
        <Form.Item name="priority" label="Priority">
          <Slider
            style={{
              width: "300px",
              marginLeft: "2rem",
            }}
            marks={{
              0: {
                style: {
                  color: "#1a1a1a",
                },
                label: "None",
              },
              1: {
                style: {
                  color: "#00ff2a",
                },
                label: "Low",
              },
              2: {
                style: {
                  color: "#00ccff",
                },
                label: "Medium",
              },
              3: {
                style: {
                  color: "#f5864f",
                },
                label: "High",
              },
              4: {
                style: {
                  color: "#ff3c01",
                },
                label: "Critical",
              },
            }}
            defaultValue={initialValues?.priority || 0}
            max={4}
          />
        </Form.Item>
        <Form.Item
          className="custom-form-item"
          name="client"
          rules={[{ required: true, message: "Please select a client" }]}
          label={"client"}
        >
          <Select
            className="custom-select"
            showSearch
            optionFilterProp="children"
            onChange={(value) => { }}
            onSearch={(value) => { }}
            placeholder="Select a client"
          >
            {clients?.map((client) => (
              <Select.Option key={client.id} value={client.id}>
                {client.company_name}, {client.address}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="id" style={{ display: "none" }}></Form.Item>
        <Row gutter={16}>
          {" "}
          {/* Add gutter for spacing between columns */}
          <Col span={12}>
            <Form.Item name="date" className="custom-form-item" label={"Date"}>
              <DatePicker
                placeholder="Date"
                className="custom-datepicker"
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="deliver_date" className="custom-form-item" label={"Delivery Date"}>
              <DatePicker
                placeholder="Delivery Date"
                className="custom-datepicker"
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="custom-form-item" name="designer" label={"Designer"}>
          <Input className="custom-input" placeholder="Designer" />
        </Form.Item>
        <Row gutter={16}>
          {" "}
          {/* Add gutter for spacing between columns */}
          <Col span={12}>
            <Form.Item name="quantity" className="custom-form-item" label={"Quantity"}>
              <InputNumber
                placeholder="Quantity"
                className="custom-datepicker"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="category" className="custom-form-item">
              <Input placeholder="Category" className="custom-datepicker" label={"Category"} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="custom-form-item" name="order_by" label={"Order By"}>
          <Input className="custom-input" placeholder="Order By" />
        </Form.Item>
        <Form.Item className="custom-form-item" name="rate" label={"Rate"}>
          <Input type="number" className="custom-input" placeholder="Rate" />
        </Form.Item>
        <div style={{ paddingLeft: "10px", fontWeight: "bold" }}>Sizes & Quantity</div>{" "}
        <br />
        <div>
          <FileForm form={form} />
        </div>
        <div
          style={{
            border: "1px solid grey",
            padding: "20px",
            paddingLeft: "30px",
            paddingRight: "35px",
            marginBottom: "20px",
            boxShadow: "0 1px 2px grey",
            borderRadius: "8px",
          }}
        >
          <h2 style={{ marginBottom: "10px" }}>Requirements</h2>
          <Row gutter={5}>
            <Col span={12}>
              <SubCatDropDown
                handleSelect={handleSelect}
                data={GarmentTypeArray}
                name={"garmenttype"}
                label={"Product Type"}
              />
            </Col>
            <Col span={12}>
              {/* {console.log(form.getFieldValue())} */}
              {garmentType === "Top Type" ? (
                <SubCatDropDown
                  data={array?.top_types}
                  name={"toptype"}
                  label={"Top Type"}
                />
              ) : garmentType === "Bottom Type" ? (
                <SubCatDropDown
                  data={array?.bottom_types}
                  name={"bottomtype"}
                  label={"Bottom Type"}
                />
              ) : null}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <SubCatDropDown
                data={array?.panel_piping_types}
                name={"panel_piping_type"}
                label={"Pattern Type"}
              />
            </Col>
            <Col span={12}>
              <Form.Item name="fly">
                <label >Fly</label>

                <Input placeholder={"Fly"} />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <SubCatDropDown
                data={array?.fabrics}
                name={"fabrics"}
                label={"Fabrics"}
              />
            </Col>


            <Col span={12}>
              <SubCatDropDown
                data={array?.colors}
                name={"colors"}
                label={"Colors"}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <SubCatDropDown
                data={array?.collar_types}
                name={"collar_type"}
                label={"Collar Type"}
              />
            </Col>
            <Col span={12}>
              <SubCatDropDown
                data={array?.button_types}
                name={"button_type"}
                label={"Button Type"}
              />
            </Col>

          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <SubCatDropDown
                data={array?.logo}
                name={"logos"}
                label={"Logo"}
              />
            </Col>
            <Col span={12}>
              <label >Font Name</label>

              <Form.Item name="font_name">
                <Input placeholder={"Font Name"} />
              </Form.Item>
            </Col>

          </Row>
          {/* <Row gutter={16}>
          <Col span={12}>
              <SubCatDropDown
                data={array?.kids_bottom_sizes}
                name={"kids_bottom_size"}
                label={"Kids Bottom Size"}
              />
            </Col>
            <Col span={12}>
              <SubCatDropDown
                data={array?.bottom_size_indians}
                name={"bottom_size_indian"}
                label={"Bottom Size Indian"}
              />
            </Col>
          </Row> */}

          <Row gutter={16}>
            <Col span={12}>
              <label >Pocket</label>
              <Form.Item name="pocket">
                <Input placeholder={"Pocket"} />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <SubCatDropDown
                data={array?.top_size_lycra_indians}
                name={"top_size_lycra_indian"}
                label={"Top Size Lycra Indian"}
              />
            </Col> */}
          </Row>
          <Row gutter={16}>
            {/* <Col span={12}>
              <Form.Item name="length_and_bottom">
                <Input placeholder={"Length and Bottom Size"} />
              </Form.Item>
            </Col> */}


          </Row>
          <Row gutter={16}>
            {/* <Col span={}> */}
            <label >Special Instruction</label>
            <Form.Item name={"special_instruction"} style={{ width: "100%" }} >
              <TextArea
                // data={array?.special_instruction}
                style={{ width: "100%" }}
                placeholder={"Special Instruction"}
              />
            </Form.Item>
            {/* </Col> */}

          </Row>
        </div>


        <div style={{ display: "flex", flexDirection: "column", gap: "20px", marginBottom: "20px" }}>
          {filePreviews ? (
            <img style={{ width: "200px" }} src={filePreviews} alt="" />
          ) : null}
          <div style={{ width: "100px" }}>
            {fileName && (
              <p style={{ marginBottom: '10px' }}>
                {fileName}
              </p>
            )}
            <AnimatedFileInput
              onFileSelect={handleFileSelect}
              setFilePreviews={setFilePreviews}
            />
          </div>
        </div>
        <Form.Item style={{ paddingLeft: "35%" }}>
          <Button
            type="primary"
            className="submit-button"
            htmlType="submit"
            size="large"
            loading={loa}
          >
            {initialValues?.id ? "Update Jobcard" : "Create Job Card"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateJobCard;
