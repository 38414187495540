import { Table } from 'antd'
import React from 'react'

const DetailReportView = ({data,columns}) => {
  return (
    <div
    className="body-width"
    style={{
      margin: "20px auto",
      padding: "20px",
      boxShadow: "0px 0px 4.2px rgba(0, 0, 0, 0.25)",
    }}
    >
      <Table size='medium'
      className='custom-table' dataSource={data} columns={columns}/>
    </div>
  )
}

export default DetailReportView
