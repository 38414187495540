import React from "react";
import SmallCard from "./SmallCard";
import { useFetchJobcardsCountQuery } from "../../store/store";
import { Row } from "antd";
import './cards.css'
const Cards = ({date}) => {
  const {
    data: cards,
    isLoading: loading,
    isFetching: fetchJob,
  } = useFetchJobcardsCountQuery({date:date?date:""});
  return (
    <div  style={{display:"flex",justifyContent:"space-around",margin:"20px 0px"}}  >
      {cards?.map((item) => (
        <SmallCard data={item} />
      ))}
    </div>

// <Row gutter={16}>
// <Col span={8}>
//   <Card title="Card title" bordered={false}>
//     Card content
//   </Card>
// </Col>
// <Col span={8}>
//   <Card title="Card title" bordered={false}>
//     Card content
//   </Card>
// </Col>
// <Col span={8}>
//   <Card title="Card title" bordered={false}>
//     Card content
//   </Card>
// </Col>
// </Row>
  );
};

export default Cards;
