import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { arrayToQueryParam, queryParamToArray, queryParamToArray2, stageArray } from './../../atoms/static/Static';
import { useDispatch, useSelector } from 'react-redux';
import { Client_filter, Client_filter_completed, JobCard_page, Stage_filter, jobCard_pageComplete } from './../../store/mutation/UserSlice';
const MultidropDown = ({client_company,c}) => {
  const {client_filter,stage_filter,client_filter_completed} = useSelector((state) => state.user);
  const [selectedItems, setSelectedItems] = useState(queryParamToArray(client_filter));
  const [stage, setStage] = useState(queryParamToArray2(stage_filter));
  const dispatch=useDispatch()
  const [completed, setCompleted] = useState(queryParamToArray(client_filter_completed));

  const handleselect=(data)=>{
    const queryString = arrayToQueryParam("client", data);
    dispatch(JobCard_page(1))
    dispatch(Client_filter(queryString))
    setSelectedItems(data)

  }

  const handleselect2=(data)=>{
    const queryString = arrayToQueryParam("current_stage", data);
    dispatch(JobCard_page(1))
    dispatch(Stage_filter(queryString))
    setStage(data)

  }
  // useEffect(() => {
  //   if(client_filter){

  //   }else{

  //     if(selectedItems||selectedItems!==[]){
  //       const queryString = arrayToQueryParam("client", selectedItems);
    
  //     }
  //   }

  // }, [selectedItems]);
  // useEffect(() => {
  //   if(stage||stage!==[]){
  //       const queryString = arrayToQueryParam("current_stage", stage);
  //       dispatch(jobCard_page(1))
  //       dispatch(Stage_filter(queryString))

  //   }

  // }, [stage]);
  if(client_company){
    return (
        <Select
          mode="multiple"
          placeholder="Select Client"
          value={selectedItems}
          onChange={handleselect}
          style={{
            width: '100%',
            margin:"0px 10px"
          }}
          options={client_company?.map((item) => ({
            value: item.id,
            label: item.company_name,
          }))}
          filterOption={(inputValue, option) =>
            option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
        />
      );
  }else{
    return (

      <>
        <Select
          mode="multiple"
          placeholder="Select Stage"
          value={stage}
          onChange={handleselect2}
          style={{
            width: '100%',
            margin:"0px 10px"
          }}
          options={stageArray?.map((item) => ({
            value: item,
            label: item,
          }))}
          />
          </>
      );

  }

};
export default MultidropDown;