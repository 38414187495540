import React, { useEffect, useState } from "react";
import ForColumn from "./charts/ForColumn";
import LoadingHV from "./../../atoms/loading/LoadingHV";
import "./home.css";
import ForMat from "./charts/ForMat";
import { useFetchArrayQuery, useFetchJobcardsCountClientQuery, useFetchJobcardsCountPerYearQuery, useFetchJobcardsCountQuery } from "../../store/store";
import { Card, Col, Row, Select } from 'antd';
import Cards from "../../components/cards/Cards";
import { Option } from "antd/es/mentions";
import BarGraph from "./charts/barGraph";

const Home = () => {
  const {
    data: array,
    isLoading: array_loading,
    isFetching: array_fetch,
  } = useFetchArrayQuery();
  const {
    data: jobcardcount,
    isLoading: jobcardcount_loading,
    isFetching: jobcardcount_fetch,
  } = useFetchJobcardsCountQuery({date:""});
  const {
    data: jobcardcountclient,
    isLoading: jobcardcountclient_loading,
    isFetching: jobcardcountclient_fetch,
  } = useFetchJobcardsCountClientQuery();
  const {
    data: jobcardcountyear,
    isLoading: jobcardcountyear_loading,
    isFetching: jobcardcountyear_fetch,
  } =   useFetchJobcardsCountPerYearQuery();
const [newArray, setnewArray] = useState();
const [newCountJob, setNewCountJob] = useState();
const [newCountClient, setNewCountClient] = useState();
const [jobcardYear, setJobcardYear] = useState();
  useEffect(() => {
    if (array) {
      const newData = Object.entries(array)?.map(([type, value]) => ({
        type,
        value: value.length
    }));
    setnewArray(newData);
    }
  }, [array]);
  useEffect(() => {
    if (jobcardcount) {
      const newDataForCount = jobcardcount.map((item) => ({
        type: item?.stage,
        sales: item?.num_clothing_cards,
      }));
      setNewCountJob(newDataForCount);
    }
  }, [jobcardcount]);
  useEffect(() => {
    if (jobcardcountclient) {
      const newDataForCount = jobcardcountclient.map((item) => ({
        type: item?.client_id,
        sales: item?.num_clothing_cards,
      }));
      setNewCountClient(newDataForCount);
    }
  }, [jobcardcountclient]);
  useEffect(() => {
    if (jobcardcountyear) {
      const newDataForCount = jobcardcountyear.map((item) => ({
        year: item?.month,
        value: item?.count,
      }));
      setJobcardYear(newDataForCount);
    }
  }, [jobcardcountyear]);
  const stausXcount = [
    { type: "hello", sales: 21 },
    { type: "bye", sales: 22 },
    { type: 12, sales: 23 },
  ];
  const stausXcount2 = [
    { type: "hello", value: 21 },
    { type: "bye", value: 22 },
    { type: 12, value: 23 },
  ];
  console.log(jobcardcount)
  return ( 
    <>
      {/* <Row gutter={16}>
        <Col span={8}>
          <Card title="Card title" bordered={false}>
            Card content
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Card title" bordered={false}>
            Card content
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Card title" bordered={false}>
            Card content
          </Card>
        </Col>
      </Row> */}
      <Cards/>
      <div style={{ minHeight: "60vh" }}>
        <div className="pie-char-custom">
          <div className="pie-char-inside">
            <h2 className="h2-pie-char">JobCard Count Per Stage</h2>
            {newCountJob ? <ForColumn data={newCountJob} /> : <LoadingHV />}
          </div>
          <div className="pie-char-inside">
            <h2 className="h2-pie-char">JobCard Count Per Client</h2>
            {newCountClient ? <ForColumn data={newCountClient} /> : <LoadingHV />}
          </div>

       
          {/* <div  className="pie-char-inside" >
        <h2 className="h2-pie-char">Item Categories</h2>


          {newItem ? <ForMat data={newItem} /> : <LoadingBox/>}
        </div> */}
        </div>
        <div className="pie-char-custom">
      

          <div className="pie-char-inside" >
            <h2 className="h2-pie-char">Material Count</h2>


            {newArray ? <ForMat data={newArray} /> : <LoadingHV />}
          </div>
        
          <div className="pie-char-inside">
            <h2 className="h2-pie-char">JobCard Count Per Year</h2>
            {jobcardYear ? <BarGraph data={jobcardYear} /> : <LoadingHV />}
          </div>
          {/* <div  className="pie-char-inside" >
        <h2 className="h2-pie-char">Item Categories</h2>


          {newItem ? <ForMat data={newItem} /> : <LoadingBox/>}
        </div> */}
        </div>
      </div>
    </>

  );
};

export default Home;
