import React, { useEffect } from "react";
import FilterPaginatedTable from "../../components/tables/FilterPaginatedTable";
import {
  useFetchClientsQuery,
  useFetchJobcardsQuery,
  useUpdateJobCardMutation,
} from "../../store/store";
import { MakeForm, STAGES, flatMap } from "../../atoms/static/Static";
import {
  getFormalName,
  getNextStage,
  getPreviousStage,
} from "../../atoms/static/Functions";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import "./form/jobcard.css";
import { AiFillEdit } from "react-icons/ai";
import { useSelector } from "react-redux";
import Cards from "../../components/cards/Cards";
import Heading from "../../atoms/heading/Heading";
import MultidropDown from "./MultidropDown";
const JobCardCompleted = () => {
  const navigate = useNavigate();
  const [updateJobcard, updateResponseInfo] = useUpdateJobCardMutation();
  const { jobcard_page,user,client_filter,stage_filter } = useSelector(
    (state) => state.user
);
  useEffect(() => {
    if (updateResponseInfo?.isSuccess) {
      message.success("Job Card Updated");
      navigate("/jobcard");
    }
  }, [updateResponseInfo]);
  const {
    data: cards,
    isLoading: loading,
    isFetching: fetchJob,
  } = useFetchJobcardsQuery({page:jobcard_page,stage:'Completed',stage_filter,client_filter});
  
  const {
    data: clients,
    isLoading: clients_loading,
    isFetching: clients_fetch,
  } = useFetchClientsQuery();


  const columns = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      width: "10%",
      // render: (_, record,index) => <>{(index+1)+(jobcard_page*10)-10}</>,

    },
    {
      title: "Client Company",
      dataIndex: "client",
      key: "client",
      render: (_, record,index) => <>{clients?.filter((item)=>item.id===record.client)[0].company_name}</>,

    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // sorter: (a, b) =>
      //   moment(a.date, "DD/MM/YYYY").unix() -
      //   moment(b.date, "DD/MM/YYYY").unix(),
    },
    {
      title: "Deliver Date",
      dataIndex: "deliver_date",
      key: "deliver_date",
    },
    {
      title: "Last Updated By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Current Stage",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Review",

      key: "review",
      render: (_, record) => (
        <Button
          className="paginated-table-button"
          onClick={(e) => {
            e.stopPropagation();
            const previousStage = getPreviousStage(record.stage);

            const transformedA = flatMap(record);
            const newStage = {
              ...transformedA,
              stage: previousStage,

            };

            updateJobcard({
              id: record.id,
              createJobcardData: (newStage),
            });
          }}
          disabled={
            record.stage === STAGES.Designing ||
            record.stage === STAGES.Completed
          }
        >
          {record.stage === STAGES.Completed
            ? "Completed"
            : `Send back to ${getFormalName(getPreviousStage(record.stage))}`}
        </Button>
      ),
    },
    {
      title: "Forward",

      key: "forward",
      render: (_, record) => (
        <Button
          className="paginated-table-button"
          onClick={(e) => {
            e.stopPropagation();
            const nextStage = getNextStage(record.stage);
            const transformedA = flatMap(record);
            const newStage = {
              ...transformedA,
              stage: nextStage,

            };
            updateJobcard({
              id: record.id,
              createJobcardData: (newStage)
            });
          }}
          disabled={record.stage === STAGES.Completed}
        >
          {record.stage === STAGES.Completed
            ? "Completed"
            : `${
                record.stage === STAGES.Account ? "Mark as" : "Forward to"
              }  ${getFormalName(getNextStage(record.stage))}`}
        </Button>
      ),
    },
    // {
    //   title: "Edit",

    //   key: "edit",
    //   render: (_, record) => (
    //     <Button
    //       size="small"
    //       className="paginated-table-button"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         navigate(`/create-jobcard/${record.id}`);

    //       }}
    
    //     >
    //      <AiFillEdit/>
    //     </Button>
    //   ),
    // },
  ];
  return (
    <div
      className="body-width"
      style={{
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0px 0px 4.2px rgba(0, 0, 0, 0.25)",
      }}
    >
      {/* <Cards/> */}
      <Heading title={"Completed JobCard Table"}/>
      {/* <Button
        style={{
          margin: "40px 0px 20px 0px",
          background: "var(--color-primary)",
          color: "var(--light)",
          fontSize: "1rem",
          borderRadius: ".3rem",
          width: "fit-content",
          height: "100%",
        }}
        type="default"
        onClick={() => navigate("/create-jobcard")}
      >
        Create A New Job Card
      </Button>  */}
      {user?.role==="Admin"||user?.role==="Designing"?

        <div style={{ display: "flex", width: "100%" ,marginBottom:"20px", marginTop: "20px"}}>
            <MultidropDown client_company={clients} />

            {/* <MultidropDown /> */}
          </div>:null
      }
      <FilterPaginatedTable columns={columns} data={cards} page={jobcard_page} page100={"jobcard"} />
    </div>
  );
};

export default JobCardCompleted;
