// import React from 'react';
import React, { useState, useEffect } from "react";
import { Bar } from "@ant-design/plots";

const BarGraph = ({ data }) => {
  useEffect(() => {
    if (data) {
      // bar_graph()
    }
  }, [data]);

  const config = {
    data,
    xField: "value",
    yField: "year",
    seriesField: "year",
    legend: {
      position: "top-right",
    },
  };
  return (
    <div className="bar-graph-dash">
      {data ? <Bar {...config} /> : <p>loadings</p>}
    </div>
  );
};

export default BarGraph;
