import { Form, Input, InputNumber, Select, Space, Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  AiFillDelete,
  AiOutlineMinusCircle,
  AiOutlinePlus,
} from "react-icons/ai";

const FileForm = ({
  initialValues,
  form,
  currentInstance,
  onRemove,
  isSalesOrderItem,
  setCurrentInstance,
}) => {
  const fileInputRef = useRef(null);
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    // console.log(form.setFieldValue(`files[${index}].file`,file))
    form.setFieldValue(`files[${index}].file`, file);

    // form.
  };
  const asd = "sdsa";
  //   console.log(form.getFieldValue());
  //   console.log(currentInstance);

  return (
    // <div className="sub-section">

    <div className="sub-section">
      <div
        className="row-add-item"
        style={{ padding: "20px", display: "flex", flexDirection: "column" }}
      >
        <Form.List name="images">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space key={field.index} align="baseline">
                  <div className="sub-items2" style={{ display: "flex" }}>
                    <div className="sub-item2">

                      <Form.Item
                        {...field}
                        name={[field.name, "sizee"]}
                        //   name={[field.name, `title${index + 1}`]}
                        wrapperCol={{
                          offset: 1,
                          span: 16,
                        }}
                        labelCol={{
                          offset: 1,
                          span: 4,
                        }}
                        label={"Size"}
                      >

                        {/* <label>
                          <b>{"Size"} :</b>
                        </label> */}
                        <Input
                          required
                          placeholder="Size"
                          style={{ width: "190px" }}
                          // onChange={(e) =>
                          //   form.setFieldValue(
                          //     `title${index + 1}`,
                          //     e.target.value
                          //   )
                          // }
                        />
                      </Form.Item>
                    </div>
                    <div className="sub-item2" style={{marginLeft:"20px"}}>
                      <Form.Item
                        {...field}
                        name={[field.name, "count"]}
                        //   name={[field.name, `title${index + 1}`]}
                        wrapperCol={{
                          offset: 1,
                          span: 16,
                        }}
                        labelCol={{
                          offset: 1,
                          span: 6,
                        }}
                        label={"Quantity"}

                      >
                        {/* <div>
                          <b>Quantity :</b>
                        </div> */}

                        <Input
                          required
                          placeholder="Quanity"
                          style={{ width: "190px" }}
                          // onChange={(e) =>
                          //   form.setFieldValue(
                          //     `title${index + 1}`,
                          //     e.target.value
                          //   )
                          // }
                        />
                      </Form.Item>
                    </div>

                    <div className="sub-item" style={{marginLeft:"20px"}}>
                      <Button
                        danger
                        type="primary"
                        onClick={() => {
                          // onRemove(index);
                          // console.log(form.getFieldValue())
                          remove(field.name); // Remove the specific field by name
                          // const {,title[index],...other}=currentInstance

                          // remove(index);
                        }}
                      >
                        <AiFillDelete />
                      </Button>
                    </div>
                  </div>
                </Space>
              ))}
              <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<AiOutlinePlus />}
                >
                  Add Sizes
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      {/* <Form.Item
        name={["image"]}
        wrapperCol={{
          offset: 2,
          span: 12,
        }}
        labelCol={{
          span: 1,
        }}
        valuePropName="fileList"
      >
        <input
          type="file"
        />
      </Form.Item>
      <p style={{padding:"0px 20px"}}>
                       
                       <img style={{width:"100px"}} src={form.getFieldValue() && form.getFieldValue()?.image} alt="" />
                     </p> */}
    </div>
    // </div>
  );
};

export default FileForm;
