import { Steps, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import "./view.css";
import moment from "moment"; // Import moment.js
import ImageModal from "./ImageModal";
import { useFetchClientsQuery, useFetchUsersQuery, useGetClientQuery } from "../../../store/store";
import PdfViewer from "../../../atoms/PdfViewer";

const DetailView = ({ data, isLoading }) => {
  // const DetailView = () => {
  const [selectedImage, setSelectedImage] = useState(null);


  const {
    data: clientss,
    isLoading: loading,
  } = useGetClientQuery({ id: data?.client });
  const {
    data: user_fetch,
    isLoading: user_loading,
  } = useFetchUsersQuery();
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    console.log("Image Path: ", imageUrl)
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };
  const isLoading1 = false;
  /*const data1={
        "id": 16,
        "Card_number": null,
        "Date": "21/03/2023",
        "Created_by": "supervisor_warje",
        "Party_name": "schiltron",
        "Design_name": "MILAN",
        "Job_coordinator": "sureykant",
        "rate": "10",
        "Mob": "8830157770",
        "Quantity": 50,
        "Rejected_quantity": 0,
        "Finished_quantity": 0,
        "Stitches": "4376",
        "design_placement": "left chest",
        "tshirt_front_lc": "MILAN",
        "tshirt_front_lc_palla": "",
        "tshirt_front_rc": "",
        "tshirt_front_rc_palla": "",
        "tshirt_left_sleeves": "",
        "tshirt_right_sleeves": "",
        "tshirt_left_sleeves_palla": "",
        "tshirt_right_sleeves_palla": "",
        "tshirt_back": "",
        "tshirt_back_palla": "",
        "tshirt_front_above_pocket": "",
        "tshirt_front_cc": null,
        "tshirt_back_cc": null,
        "shirt_front_lc": "",
        "shirt_front_rc": "",
        "shirt_front_lc_palla": "",
        "shirt_front_rc_palla": "",
        "shirt_left_sleeves": "",
        "shirt_right_sleeves": "",
        "shirt_left_sleeves_palla": "",
        "shirt_right_sleeves_palla": "",
        "shirt_back": "",
        "shirt_back_palla": "",
        "shirt_front_above_pocket": "",
        "shirt_cuff": "",
        "shirt_cuff_palla": "",
        "shirt_collar": "",
        "shirt_collar_palla": "",
        "shirt_front_cc": null,
        "shirt_back_cc": null,
        "cap_front": "",
        "cap_left_middle": "",
        "cap_right_middle": "",
        "cap_back": "",
        "cap_velcro": "",
        "cap_visor": "",
        "cap_back_left_side": "",
        "cap_back_right_side": "",
        "cap_back_middle_right": "",
        "cap_back_middle_left": "",
        "cap_fornt_left": "",
        "cap_front_right": "",
        "bag_front": "",
        "appron_front_rc": "",
        "appron_right_lc": "",
        "appron_front_center": "",
        "waist_coat_left_sleeves": "",
        "waist_coat_right_sleeves": "",
        "pant_left_side": "",
        "pant_right_side": "",
        "pocket_patti_center": "",
        "pocket_patti_pen_side": "",
        "pocket_center": "",
        "image": null,
        "Design_width": "",
        "Design_height": "",
        "Ring_size": "",
        "Design_color_code": "BLUE/WHITE",
        "Fusing": "one side",
        "Job_in_time": "20/03/2023",
        "job_out_field": "23/03/2023",
        "special_instruction": "",
        "is_rejected": null,
        "is_approved": false,
        "current_stage": "account",
        "Last_update": "supervisor_warje",
        "Production_update_date": null,
        "client_update_date": "21/03/2023",
        "admin_update_date": "10/05/2023",
        "Finishing_update_date": null,
        "Account_update_date": null,
        "manufacturer_update_date": "08/06/2023",
        "Admin_or_client_comment": "Ok",
        "Last_update_time": "14:45:46",
        "Production_update_time": null,
        "client_update_time": "10:11:18",
        "admin_update_time": "12:37:49",
        "Finishing_update_time": null,
        "Account_update_time": null,
        "manufacturer_update_time": "14:45:46",
        "jobcard_approval_time": "16:06:35",
        "client": 8,
        "location": 3
    }*/
  const componentRef = useRef();
  const [client, setClient] = useState();

  //   const placedt = place?.find((item) => item.id === data?.location)

  const handlePrint = useReactToPrint({
    copyStyles: true,
    content: () => componentRef.current,
    documentTitle: "emp-data",
    onAfterPrint: () => alert("Print success"),
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  // Format date strings using moment.js
  const formattedCreatedTime = moment(data?.created_time).format("YYYY-MM-DD");
  //const formattedDeliverDate = moment(data?.deliver_date).format('YYYY-MM-DD');
  // Convert UTC time to IST format (yyyy-mm-dd hh-mm-ss)
  const convertToIST = (utcTime) => {
    if (utcTime) {

      return moment.utc(utcTime).local().format("YYYY-MM-DD HH:mm");
    } else {
      return ""
    }
  };

  return (
    <>
      <div className="print-button">
        <button onClick={handlePrint}>Print Out</button>
      </div>

      <Style ref={componentRef}>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            left: "50%",
            marginBottom: ".5rem",
          }}
        ></div>
        <div>
          <div className="title">Job card No : {data?.id}</div>
          <div className="row">
            <div>
              <label
                style={{ fontWeight: "bold", color: "var(--color-primary)" }}
              >
                Stage :
              </label>
              <span style={{ fontWeight: "bold" }}>{data?.stage}</span>
            </div>
          </div>
          <div className="row">
            <div>
              <label>Ordered Date:</label>
              <span>{formattedCreatedTime}</span>
            </div>
            <div>
              <label>CASH/GST:</label>
              <span>{clientss?.cash}</span>
            </div>
          </div>
          <div className="row" >

            <div>
              <label>Client:</label>
              <span>{clientss?.company_name}</span>
            </div>
            <div>
              <label>Phone:</label>
              <span>{clientss?.phone}</span>
            </div>


          </div>
          <div className="row" style={{ gridTemplateColumns: "2fr 1fr " }}>

            <div>
              <label>Address:</label>
              <span>{clientss?.address}</span>
            </div>
            <div>
              <label>GST No:</label>
              <span>{clientss?.gst_no}</span>
            </div>
          </div>
          <div className="row">
            <div>
              <label>Designer:</label>
              <span>{data?.designer}</span>
            </div>
            <div>
              <label>Dispatch Date:</label>
              <span>{data?.deliver_date}</span>
            </div>
          </div>
          <div className="row">
            <div>
              <label>Created By:</label>
              <span>{user_fetch?.filter((item) => item.id === data?.created_by)[0]?.username}</span>
            </div>
            <div>
              <label>Quantity:</label>
              <span>{data?.quantity}</span>
            </div>
          </div>
          <div className="row">

            <div>
              <label>Category:</label>
              <span>{data?.category}</span>
            </div>
            <div>
              <label>Rate:</label>
              <span>{data?.rate}</span>
            </div>

          </div>

          <div className="section">
            <div className="section-title">Sizes Requirement</div>
            <div className="" style={{ gridTemplateColumns: "1fr 1fr" }}>
              {data?.images?.map((item) => (
                <p>
                  <div>
                    <b>

                      {item.sizee}{" : "}
                    </b>

                    <span>{item.count}</span>
                  </div>
                </p>
              ))}
            </div>
          </div>
          <div className="section">
            <div className="section-title">Requirements</div>

            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label>Product Type:</label>
                <span>{data?.garmenttype}</span>
              </div>
              {data?.garmenttype === "Top Type" ? (
                <div>
                  <label>Top Type:</label>
                  <span>{data?.toptype}</span>
                </div>
              ) : (
                <div>
                  <label>Bottom Type:</label>
                  <span>{data?.bottomtype}</span>
                </div>
              )}
            </div>
            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label>Fabrics:</label>
                <span>{data?.fabrics}</span>
              </div>
              <div>
                <label>Collar Type:</label>
                <span>{data?.collar_type}</span>
              </div>
            </div>
            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label>Button Type:</label>
                <span>{data?.button_type}</span>
              </div>
              <div>
                <label>Pattern Type:</label>
                <span>{data?.panel_piping_type}</span>
              </div>
            </div>
            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label>Top Size Lycra Indian:</label>
                <span>{data?.top_size_lycra_indian}</span>
              </div>
              <div>
                <label>Bottom Size Indian:</label>
                <span>{data?.bottom_size_indian}</span>
              </div>
            </div>
            <div
              className="row"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label>Kids Bottom Size:</label>
                <span>{data?.kids_bottom_size}</span>
              </div>
              <div>
                <label>Colors:</label>
                <span>{data?.colors}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row-qty">
          <div>
            <label>Logos:</label>
            <span>{data?.logos}</span>
          </div>
          <div>
            <label>Fly:</label>
            <span>{data?.fly}</span>
          </div>
          <div>
            <label>Font Name:</label>
            <span>{data?.font_name}</span>
          </div>
        </div>
        <div className="row">
          {/* <div>
            <label>Length and Bottom :</label>
            <span>{data?.length_and_bottom}</span>
          </div> */}
          <div>
            <label>Pocket :</label>
            <span>{data?.pocket}</span>
          </div>
        </div>
        <div className="row" style={{ gridTemplateColumns: "1fr" }}>
          {/* <div>
            <label>Length and Bottom :</label>
            <span>{data?.length_and_bottom}</span>
          </div> */}
          <div>
            <label style={{ color: "red" }}>Special Instruction :</label>
            <b>{data?.special_instruction}</b>
          </div>
        </div>
        <div className="section">
          <div className="section-title">Completion Timeline</div>
          <div className="row">
            <div>
              <label>Designer Time : </label>
              <span>{convertToIST(data?.designer_time)}</span>
            </div>
            <div>
              <label>Sublimation Time : </label>
              <span>{convertToIST(data?.sublimation_time)}</span>
            </div>
            <div>
              <label>Cutting Time :</label>
              <span>{convertToIST(data?.cutting_time)}</span>
            </div>
            <div>
              <label>Stitching Time :</label>
              <span>{convertToIST(data?.stitching_time)}</span>
            </div>
            <div>
              <label>Packaging Time :</label>
              <span>{convertToIST(data?.packaging_time)}</span>
            </div>
            <div>
              <label>Completed Time :</label>
              <span>{convertToIST(data?.completed_time)}</span>
            </div>
          </div>
        </div>


        <div className="section" style={{ pageBreakBefore: "always" }}>
          <div className="section-title">Images</div>
          <div className="row" style={{ gridTemplateColumns: "1fr" }}>
            {data?.image && (
              <>
                {data.image.toLowerCase().endsWith('.pdf') ? (
                  <PdfViewer data={data.image} />
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={data.image}
                      style={{ width: "100%", cursor: "pointer" }}
                      alt=""
                      onClick={() => handleImageClick(data.image)}
                    />
                  </div>
                )}
              </>
            )}
            <ImageModal src={selectedImage} onClose={handleCloseModal} />
          </div>
        </div>

        {/*<div className="row">
            <div>
              <label>Product</label>
              <span>{data?.pocket_patti_pen_side}</span>
            </div>
            </div>*/}
      </Style>
    </>
  );
};

const Style = styled.div`
  max-width: 900px;
  width: 100%;
  padding: 2rem;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  background-color: #f8f8f8;
  position: relative;
  overflow: hidden;
  /* &::after {
    content: "";
    display: block;
    border-width: 0px 75px 75px 0px;
    border-style: solid;
    border-color: #d7d7d7 transparent;
    position: absolute;
    top: 0;
    right: 0;
  }
  &::after {
    content: "";
    display: block;
    border-width: 0px 75px 75px 0px;
    border-style: solid;
    border-color: #d7d7d7 #fff;
    box-shadow: -5px 6px 10px rgb(129, 127, 127);
    position: absolute;
    top: 0;
    right: 0;
  } */

  .img {
    width: 100%;
    height: auto;
  }

  .title {
    font-size: 2.2rem;
    padding-bottom: 1rem;
    text-align: center;
    font-weight: 900;
    color: var(--color-primary);
  }

  label {
    font-size: 1rem;
    color: black;
  }
  span {
    font-size: 1rem;
    font-weight: 600;
    color: black;
  }
  .row {
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    margin: 0.4rem 0;
    border-radius: 0.2rem;
    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .row-qty {
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 0.5rem;
    gap: 2rem;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    margin: 0.4rem 0;
    border-radius: 0.2rem;
    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .section {
    padding: 1rem;
    border: 1px solid grey;
    margin: 1rem 0;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    .section-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .sub-section-title {
      font-size: 1rem;
      font-weight: 500;
    }
    .row {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .sub-section {
    background: white;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .client-comment {
    div {
      display: flex;
      gap: 1rem;
    }

    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 0;
    &.rejected {
      border: 2px solid rgba(255, 0, 0, 0.7);
    }
    &.approved {
      border: 2px solid #90e590;
    }
  }

  .tag {
    .ant-tag {
      padding: 0.3rem 0.5rem;
      font-size: 0.8rem;
    }
  }
  .rejected-count,
  .finished-count {
    padding: 1rem;
    background: rgba(255, 0, 0, 0.2);
    color: white;
  }

  .finished-count {
    background: rgba(0, 255, 0, 0.2);
  }
`;

export default DetailView;
