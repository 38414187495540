import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./url";
import { message } from "antd";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ username, password }, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const { data } = await axios.post(
        `${url}/auth/jwt/create/`,
        { username, password },
        config
      );
      // store user's token in local storage
      const { dispatch } = thunkAPI;
      localStorage.setItem("userToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      dispatch(getUser());
      return { data, userToken: data.access };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const Client_filter = createAsyncThunk(
  "Client_filter",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Client_filter", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Client_filter_completed = createAsyncThunk(
  "Client_filter_completed",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Client_filter", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Stage_filter = createAsyncThunk(
  "Stage_filter",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Stage_filter", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getUser = createAsyncThunk(
  "api/account/me",
  async (_, thunkAPI) => {
    try {
      // const res = await fetch(`${url}auth/users/me/`, {
      const res = await fetch(`${url}/basicauth/me/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          Accept: "application/json",
        },
      });
      const data = await res.json();

      localStorage.setItem("user", JSON.stringify({ ...data }));
      if (res.status === 200) {
        message.success("Login SuccessFull");

        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const JobCard_page = createAsyncThunk(
  "jobcard_page",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("jobcard_page", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
// initialize userToken from local storage
const userToken = localStorage.getItem("userToken", "role")
  ? localStorage.getItem("userToken", "role")
  : null;

const initialState = {
  loading: true,
  user: null,
  userToken: localStorage.getItem("userToken"),
  success: false,
  isAuthenticated: false,
  error: null,
  jobcard_page: 1,
  client_filter: "",
  client_filter_completed: "",
  stage_filter: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state, action) {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userToken");

      return {
        ...state,
        userToken: "",
        user: "",
        loading: false,
        isAuthenticated: false,
        userToken: "",
        jobcard_page: 1,
        client_filter: "",
        client_filter_completed: "",
        stage_filter: "",
      };
    },
  },
  extraReducers: (builder) => {
    // login user
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userToken = payload.userToken;
        state.isAuthenticated = true;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isAuthenticated = false;
        state.userToken = "";
      })
      .addCase(getUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isAuthenticated = false;
        state.userToken = "";
      })
      .addCase(JobCard_page.fulfilled, (state, action) => {
        state.jobcard_page = action.payload;
      })
      .addCase(Client_filter.fulfilled, (state, action) => {
        state.client_filter = action.payload;
      })
      .addCase(Client_filter_completed.fulfilled, (state, action) => {
        state.client_filter_completed = action.payload;
      })
      .addCase(Stage_filter.fulfilled, (state, action) => {
        state.stage_filter = action.payload;
      });
  },
});
// export default userSlice.reducer;

export const { logout } = userSlice.actions;

// export default userSlice.reducer;
export default userSlice.reducer;
