import React from 'react';
import './ImageModal.css';

const ImageModal = ({ src, onClose }) => {
  if (!src) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <span className="close" onClick={onClose}>&times;</span>
      <img className="modal-content" src={src} alt="" />
    </div>
  );
};

export default ImageModal;
