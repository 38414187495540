import React from 'react'

const Heading = ({title}) => {
  return (
    <div>
      <h2 style={{color:"white",background:"var(--color-primary)",marginTop:"20px",padding:"10px 20px"}}>{title}</h2>
    </div>
  )
}

export default Heading
