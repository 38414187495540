import React from 'react'
import { ClipLoader } from 'react-spinners'

const LoadingHV = () => {
  return (
    <div style={{height:"70vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <ClipLoader/>
    </div>
  )
}

export default LoadingHV
