import { STAGES, STAGES_LIST } from "./Static";

export const getNextStage = (stage) => {
  let isValid = STAGES_LIST.includes(stage);
  if (!isValid) return "invalid";
  let index = STAGES_LIST.indexOf(stage);
  if (index === STAGES_LIST.length - 1) return "-";
  return STAGES_LIST[index + 1];
};

export const getPreviousStage = (stage) => {
  let isValid = STAGES_LIST.includes(stage);
  if (!isValid) return "invalid";
  let index = STAGES_LIST.indexOf(stage);
  if (index === 0) return "-";
  return STAGES_LIST[index - 1];
};

export const getFormalName = (stage) => {
  switch (stage) {
    case STAGES.Designing:
      return "Designing";
    case STAGES.Sublimation:
      return "Sublimation";
    case STAGES.Cutting:
      return "Cutting";
    case STAGES.Stitching:
      return "Stitching Process";
    case STAGES.Packaging:
      return "Packaging";
    case STAGES.Account:
      return "Account";
    case STAGES.Completed:
      return "Completed";
    default:
      return "-";
  }
};


export const IDate=(date)=>{
  if(date){

    const IndianDate=new Date(date).toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
    return IndianDate
  }else{
    return ""
  }
}

export function IDate2(utcDateString) {
  const date = new Date(utcDateString);

  // Get date components
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-indexed
  const year = date.getUTCFullYear();
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Determine am/pm
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Pad minutes and seconds with leading zeros if needed
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  const hoursStr = hours < 10 ? '0' + hours : hours;

  // Format the date string
  const formattedDate = `${day}/${month}/${year} ${hoursStr}:${minutesStr} ${ampm}`;

  return formattedDate;
}
export const ConvertDate=(date)=>{
  if(date){

    const today = new Date(date);

    // Extract the day, month, and year
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear().toString().slice(-2);
  
    // Format the date as dd/mm/yyyy
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate
  }else{
    return ""
  }
}

export const MakeDate=(date)=>{
  const inputDate = new Date(date);
  inputDate.setDate(inputDate.getDate() );

  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}