import React, { useState } from "react";
import { useFetchClientsQuery, useFetchJobcardsHistoryQuery, useFetchJobcardsQuery } from "../../store/store";
import { useSelector } from "react-redux";
import FilterPaginatedTable from "../../components/tables/FilterPaginatedTable";
import { IDate, MakeDate } from "../../atoms/static/Functions";
import Cards from "../../components/cards/Cards";
import Heading from "../../atoms/heading/Heading";
import { Button, DatePicker, Form, message } from "antd";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Report = () => {
  const { jobcard_page,user } = useSelector((state) => state.user);
const [dateAndTime, setDateAndTime] = useState("");
const navigate=useNavigate()
  // const {
  //   data: cards,
  //   isLoading: loading,
  //   isFetching: fetchJob,
  // } = useFetchJobcardsQuery({page:jobcard_page,stage:user?.role,stage_filter:"",client_filter:""});
  const {
    data: history,
    isLoading: loading_history,
    isFetching: fetchJob_history,
  } = useFetchJobcardsHistoryQuery({page:jobcard_page,date:dateAndTime});
  const {
    data: clients,
    isLoading: clients_loading,
    isFetching: clients_fetch,
  } = useFetchClientsQuery();

  const columns = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (_, record, index) => <>{index + 1 + jobcard_page * 10 - 10}</>,
    },
 
    {
      title: "Client Company",
      dataIndex: "client",
      key: "client",
      render: (_, record,index) => <>{clients?.filter((item)=>item.id===record.client)[0].company_name}</>,

    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // sorter: (a, b) =>
      //   moment(a.date, "DD/MM/YYYY").unix() -
      //   moment(b.date, "DD/MM/YYYY").unix(),
    },
    {
      title: "Deliver Date",
      dataIndex: "deliver_date",
      key: "deliver_date",
    },
    {
      title: "Last Updated By",
      dataIndex: "last_update",
      key: "last_update",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Current Stage",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Created At",
      dataIndex: "created_time",
      key: "created_time",
      render: (_, record) => <>{IDate(record?.created_time)}</>,
    },
  ];
  const columns2 = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (_, record, index) => <>{index + 1 + jobcard_page * 10 - 10}</>,
    },
    {
      title: "Client Company",
      dataIndex: ["client","company_name"],
      key: ["client","company_name"],
    },
    // {
    //   title: "Client Company",
    //   dataIndex: "id",
    //   key: "id",
    // },

   
    {
      title: "Current Stage",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Created At",
      dataIndex: "created_time",
      key: "created_time",
      render: (_, record,text) => <>{IDate(record?.created_time)}</>,
    },
    {
      title: "Sublimation Time",
      dataIndex: "sublimation_time",
      key: "sublimation_time",
      render: (_, record,text) => <>{IDate(record?.sublimation_time)}</>,
    },
    {
      title: "Cutting Time",
      dataIndex: "cutting_time",
      key: "cutting_time",
      render: (_, record,text) => <>{IDate(record?.cutting_time)}</>,
    },
    {
      title: "Stitching Time",
      dataIndex: "stitching_time",
      key: "stitching_time",
      render: (_, record,text) => <>{IDate(record?.stitching_time)}</>,
    },
    {
      title: "Packaging Time",
      dataIndex: "packaging_time",
      key: "packaging_time",
      render: (_, record,text) => <>{IDate(record?.packaging_time)}</>,
    },
    {
      title: "Account Time",
      dataIndex: "account_time",
      key: "account_time",
      render: (_, record,text) => <>{IDate(record?.account_time)}</>,
    },
    {
      title: "Completed Time",
      dataIndex: "completed_time",
      key: "completed_time",
      render: (_, record,text) => <>{IDate(record?.completed_time)}</>,
    },
    {
      title: "View",

      key: "view",
      render: (_, record) => (
        <Button
          size="small"
          className="paginated-table-button"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/detail-jobcard/${record.id}`);

          }}
    
        >
         <FaEye/>
        </Button>
      ),
    },
  ];
  const handleFinish = (data) => {
if(data?.from&&data?.to){

  const newData=`&from_created_time=${MakeDate(data.from)}&to_created_time=${MakeDate(data.to)}`
  setDateAndTime(newData);
}else if(!data?.from&&!data?.to){
  setDateAndTime("");

}
else{
  message.error("Please Select Date")
}

  };
  return (
    <div
      className="body-width"
      style={{
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0px 0px 4.2px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div style={{ marginBottom: "30px" }}>
        <Cards date={dateAndTime}/>
        <Heading title={"Report Table"} />
      </div>
      {/* <FilterPaginatedTable
        columns={columns}
        data={cards}
        page={jobcard_page}
        page100={"jobcard"}
        navi={"report"}
      /> */}
      <div style={{margin:"20px 0px"}}>
        <Form layout="inline" onFinish={handleFinish}>
          <Form.Item label="From Date" name="from">
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item label="To Date" name="to">
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>

          {/* submit button */}
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" size="middle">
              Search
            </Button>
          </Form.Item>
          <Button>
            <div>CVS LINK</div>
          </Button>
        </Form>
      </div>
      <FilterPaginatedTable
        columns={columns2}
        data={history}
        page={jobcard_page}
        page100={"jobcard"}
        navi={"report"}
      />
      {/* <FilterPaginatedTable
        columns={columns2}
        data={cards}
        page={jobcard_page}
        page100={"jobcard"}
        navi={"report"}
      /> */}
    </div>
  );
};

export default Report;
