import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";

export const SlidebarDatax = [
  {
    title: "Dashboard",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    // iconclosed: <RiIcons.RiArrowDownFill/>,
    // iconopened: <RiIcons.RiArrowUpFill/>,
    // subnav: [
    //     {
    //         // title: "Dashboard",
    //         path: "/",
    //         // icon: <IoIcons.IoIosPaper/>,
    //     },
    //     {
    //         // title: "Revenue",
    //         path: "/revenue",
    //         // icon: <IoIcons.IoIosPaper/>,
    //     },
    // ]
  },
  {
    title: "Job Card",
    path: "/jobcard",
    icon: <AiIcons.AiFillHome />,
    // iconclosed: <RiIcons.RiArrowDownFill/>,
    // iconopened: <RiIcons.RiArrowUpFill/>,
    // subnav: [
    //     {
    //         title: "Job Cards",
    //         path: "/jobcard",
    //         icon: <IoIcons.IoIosPaper/>,
    //     },
    // ]
  },
  {
    title: "Inventory",
    path: "/inventory",
    icon: <MdIcons.MdOutlineInventory2 />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Caps",
        path: "/caps",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Material",
        path: "/material",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports",
    icon: <FaIcons.FaClipboardList />,
  },
  {
    title: "Created Cap",
    path: "/createdcap",
    icon: <FaIcons.FaCartPlus />,
  },
  {
    title: "Inventory Report",
    path: "/invenreport",
    icon: <FaIcons.FaCartPlus />,
  },
];


export const SlidebarData =[
  {
    title: "Dashboard",
    path: "/",
    icon: <AiIcons.AiFillHome />,
  },
  {
    title: "Job Card",
    path: "/jobcard",
    icon: <AiIcons.AiFillHome />,
  },
  {
    title: "Completed Job Card",
    path: "/jobcard-completed",
    icon: <AiIcons.AiFillHome />,
  },

  {
    title: "Reports",
    path: "/reports",
    icon: <FaIcons.FaClipboardList />,
  },
]

export const SlidebarData2 =[

  {
    title: "Job Card",
    path: "/jobcard",
    icon: <AiIcons.AiFillHome />,
  },

]