import { Button, Divider, Form, Input, Select, Space } from "antd";
import React, {
  useEffect,
  useRef,
  useState,
  useSyncExternalStore,
} from "react";
import "./subcat.css";
import { PlusOutlined } from "@ant-design/icons";

let index = 0;

const SubCatDropDown = ({ data, name, label, array, handleSelect }) => {
  const [items, setItems] = useState(data);
  const [_name, setName] = useState("");
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  // console.log(props.options)
  const handleChange = (value) => {
    // props.value(value);
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  //  console.log(props.options)
  //  setItems(props.options)
  //  console.log(items)

  const onNameChange = (e) => {
    setName(e.target.value);
    // console.log(number);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, _name || `New item ${index++}`]);
    setName("");
    console.log(_name);
  };
  return (
    <div>
      <label >{label}</label>
      <Form.Item className="custom-form-items" name={name}>
        {/* <Select
          onSelect={handleSelect}
          className="custom-select"
          showSearch
          optionFilterProp="children"
          placeholder={label}
        >
          {data?.map((item) => (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          ))}
        </Select> */}
        {/* <label>
          <b>{label} :</b>
        </label> */}

        <Select
          // defaultValue={number}
          onSelect={handleSelect}
          // value={"number"}
          // value={"SDF"}
          // onSelect={"sdf?"}
          // aria-selected={"sd"}
          placeholder={`Select ${label}`}
          ref={selectRef}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  style={{ width: "100px" }}
                  placeholder={`Please enter manual ${label}`}
                  ref={inputRef}
                  value={_name}
                  onChange={onNameChange}
                />
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                  Add {label}
                </Button>
              </Space>
            </>
          )}
        >
           <Select.Option value={null||""} key={""}>
              Select {label}
            </Select.Option>
          {items?.map((item) => (
            <Select.Option value={item} key={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default SubCatDropDown;
