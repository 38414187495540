import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import "./pdfviewer.css";

// Set up the worker for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Styled Components
const Container = styled.div`
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  background-color: #008184; /* Green */
  border: none;
  color: white;
  padding: 6px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #008184;
  }

  &:disabled {
    background-color: #9E9E9E; /* Grey when disabled */
    cursor: not-allowed;
  }
`;

const PageControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  span {
    margin: 0 10px;
    font-size: 18px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export default function PdfViewer({ item, data }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // State to keep track of the current page

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1); // Reset to the first page on document load
  };

  const handleNextPage = () => {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  };

  const handlePreviousPage = () => {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  };

  const getFileExtension = () => {
    const parts = data.split('.');
    return parts[parts.length - 1].toLowerCase();
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(data);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;

      // Determine filename based on file extension
      const extension = getFileExtension();
      const filename = `document.${extension}`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error while downloading file:', error);
    }
  };

  return (
    <Container>
      <Button onClick={handleDownload} style={{ marginBottom: "20px" }}>
        Download
      </Button>

      <Document
        file={data}
        onLoadSuccess={onDocumentLoadSuccess}
        style={{ width: "100%" }}
      >
        <Page pageNumber={pageNumber} />
      </Document>

      <PageControls>
        <Button onClick={handlePreviousPage} disabled={pageNumber <= 1}>
          Previous Page
        </Button>
        <span>Page {pageNumber} of {numPages}</span>
        <Button onClick={handleNextPage} disabled={pageNumber >= numPages}>
          Next Page
        </Button>
      </PageControls>

      {/* Optionally display an image below the PDF */}
      <ImageContainer>
        <img src={data} alt="Image" style={{ width: "100%" }} />
      </ImageContainer>
    </Container>
  );
}
