import React from "react";
import DetailReportView from "./DetailReportView";
import { useGetReportQuery } from "../../../store/store";
import { useParams } from "react-router-dom";
import { IDate } from "../../../atoms/static/Functions";

const MainReportView = () => {
  const { id } = useParams();
  const {
    data: jobcard,
    isLoading: jobcardloading,
    isFetching: fetchjobcard,
  } = useGetReportQuery({ id: id });
  const columns = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (_, record) => <>{record?.user_id?.username}</>,
    },

    {
      title: "Updated At",
      dataIndex: "time",
      key: "time",
      render: (_, record) => <>{IDate(record?.time)}</>,
    },
   
  ];
  return (
    <div>
      <DetailReportView columns={columns} data={jobcard?.history} />
    </div>
  );
};

export default MainReportView;
