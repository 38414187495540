import React, { useEffect, useState } from 'react'
import CreateJobCard from './CreateJobCard'
import { useParams } from 'react-router-dom';
import { useGetJobcardsQuery } from '../../../store/store';
import LoadingHV from '../../../atoms/loading/LoadingHV';
import moment from 'moment';

const MainJobCard = () => {
  const {id}=useParams()
  const {
    data: jobcard,
    isLoading: jobcardloading,
    isFetching: fetchjobcard,
    error:error
  } = useGetJobcardsQuery({id:id});
  const [newJob, setNewJob] = useState();
  console.log(jobcard)

  useEffect(() => {
    if(jobcard&&!fetchjobcard){

      setNewJob({
        ...jobcard,
        date:jobcard?.date ? moment(jobcard?.date, "DD/MM/YY") : null,
        deliver_date:jobcard?.deliver_date ? moment(jobcard?.deliver_date, "DD/MM/YY") : null,
      })
      
    }
   if(error){
    setNewJob({})
   }
  }, [jobcard,error]);
  console.log("lion",newJob)
  return (
    <div>
      {!fetchjobcard?

<CreateJobCard initialValues={{
        ...jobcard,
        date:jobcard?.date ? moment(jobcard?.date, "DD/MM/YY") : null,
        deliver_date:jobcard?.deliver_date ? moment(jobcard?.deliver_date, "DD/MM/YY") : null,
      }}/>:<LoadingHV/>
}
    </div>
  )
}

export default MainJobCard
